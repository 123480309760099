var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container only-heading-part" },
          [
            _c(
              "div",
              {
                staticClass: "top-page-header page-header-with-banner",
                attrs: { lang: _vm.locale },
              },
              [
                _c("div", {
                  staticClass: "head-title top-space",
                  class: _vm.syncStatus ? "syncActive" : "",
                  attrs: { lang: _vm.locale },
                }),
              ]
            ),
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-4 rounded-lg extra-padding",
                      attrs: { elevation: "3" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "app" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "px-0 pt-0 pb-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "sub-heading-with-btn d-flex flex-wrap align-baseline mb-0",
                                },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "black-text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "strategies.manageStrategies"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            "max-width": "300px",
                                            color: "primarydark",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "greyxdark--text",
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "mdi-information-outline"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("strategies.desc"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-baseline goal-header",
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "max-275 mr-2",
                                        attrs: {
                                          "append-icon": "mdi-magnify",
                                          label: _vm.$t("labels.search"),
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                      _c("v-autocomplete", {
                                        staticClass: "rounded-lg max-275 mr-2",
                                        attrs: {
                                          items: _vm.searchedApps,
                                          loading: _vm.loadingApps,
                                          "item-text": "appName",
                                          label: "Search App",
                                          "item-value": "adamId",
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          "prepend-inner-icon": "mdi-cellphone",
                                          "return-object": "",
                                          disabled: _vm.searchedApps.length < 2,
                                        },
                                        on: { change: _vm.changeApp },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                [
                                                  _c("v-img", {
                                                    staticClass: "rounded-lg",
                                                    attrs: {
                                                      color: "secondary",
                                                      "lazy-src":
                                                        data.item.appIcon,
                                                      "max-width": "30",
                                                      "max-height": "30",
                                                      "min-width": "20",
                                                      "min-height": "20",
                                                      src: data.item.appIcon,
                                                    },
                                                  }),
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.appName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.searchApp,
                                          callback: function ($$v) {
                                            _vm.searchApp = $$v
                                          },
                                          expression: "searchApp",
                                        },
                                      }),
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: "primary ",
                                              depressed: "",
                                            },
                                          },
                                          !_vm.isReadOnly()
                                            ? {
                                                click: function () {
                                                  return _vm.openCreateDialog()
                                                },
                                              }
                                            : {
                                                click: function () {
                                                  return _vm.openPermissionReadOnlyPopup()
                                                },
                                              }
                                        ),
                                        [_vm._v(" Create Strategy ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("v-data-table", {
                            staticClass: "elevation-0 pa-0 mt-4 table-style-3",
                            attrs: {
                              "items-per-page": 10,
                              headers: _vm.strategyHeaders,
                              items: _vm.allStrategies,
                              "no-data-text": _vm.$t("kai.showDataText"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.createdOn",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.localDate(item.createdOn)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.action",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "no-m-no-p",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "primarydark",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "font-22 icon-hover",
                                                                    attrs: {
                                                                      dark: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openStrategyInDialog(
                                                                          item
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " mdi-pencil-outline "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Edit")])]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "primarydark",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "font-22 icon-hover",
                                                                    attrs: {
                                                                      dark: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openStrategyDeleteDialog(
                                                                          item
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " mdi-delete-outline "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Delete")])]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.targetEntities",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return _vm._l(
                                      item.combinedEntities,
                                      function (app, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "mr-1 d-flex flex-v-center",
                                          },
                                          [
                                            _c("v-img", {
                                              staticClass: "ma-1 rounded-lg",
                                              attrs: {
                                                src: app.appIcon,
                                                "lazy-src": app.appIcon,
                                                "max-width": "30",
                                                height: "30",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                " " + _vm._s(app.appName) + " "
                                              ),
                                              app.remainingApps > 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "+" +
                                                        _vm._s(
                                                          app.remainingApps
                                                        ) +
                                                        " More"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  },
                                },
                                {
                                  key: "item.sno",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _vm._v(" " + _vm._s(index + 1) + " "),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
              ],
              2
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "600px", persistent: "" },
                model: {
                  value: _vm.strategyDialog,
                  callback: function ($$v) {
                    _vm.strategyDialog = $$v
                  },
                  expression: "strategyDialog",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "v-modal-fixed style-2" },
                  [
                    _c(
                      "v-form",
                      { ref: "strategyForm" },
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass:
                              "headline d-flex justify-space-between px-5 py-3 v-modal-header sticky-header",
                          },
                          [
                            _c("h5", { staticClass: "text-h6" }, [
                              _vm._v("Strategy"),
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "black--text",
                                attrs: {
                                  disabled: _vm.saving || _vm.syncingStrategy,
                                },
                                on: { click: _vm.closeDialog },
                              },
                              [_vm._v("mdi-close")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "pt-4 pb-4" },
                          [
                            [
                              _c(
                                "v-container",
                                { staticClass: "pa-0" },
                                [
                                  _vm.syncingStrategy
                                    ? _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "center",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _vm.syncingStrategy
                                            ? _c("v-progress-circular", {
                                                attrs: {
                                                  color: "primary",
                                                  indeterminate: "",
                                                  size: "20",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    { staticClass: "field m-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "col-7 p-0",
                                          attrs: { col: "7" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "m-0 p-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "col-10 p-0",
                                                  attrs: { col: "10" },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [_vm._v("Name ")]
                                                  ),
                                                  _c("v-text-field", {
                                                    ref: "strategyName",
                                                    attrs: {
                                                      rules: [
                                                        _vm.duplicateNameRule,
                                                      ],
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      readonly:
                                                        _vm.syncingStrategy,
                                                      disabled: _vm.disableName,
                                                    },
                                                    on: {
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.callSyncChanges()
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.handleBlurEvent()
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.name,
                                                      callback: function ($$v) {
                                                        _vm.name = $$v
                                                      },
                                                      expression: "name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "col-2 p-0 mt-32",
                                                  attrs: { col: "2" },
                                                },
                                                [
                                                  _vm.disableName
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            bottom: "",
                                                            color:
                                                              "primarydark",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "font-22 icon-hover",
                                                                            attrs: {
                                                                              dark:
                                                                                "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.enableField(
                                                                                  "disableName"
                                                                                )
                                                                              },
                                                                            },
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-pencil-outline "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2895869970
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Edit"),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.disableName
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-v-center m-h-input",
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-3",
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.name ||
                                                                  _vm.syncingStrategy,
                                                                width: 70,
                                                                height: 28,
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                mousedown: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onMouseDownOfSaveOrCancel()
                                                                },
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.handleManualSave()
                                                                },
                                                              },
                                                            },
                                                            [_vm._v("Save")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.name ||
                                                                  _vm.syncingStrategy,
                                                                color:
                                                                  "primary",
                                                                width: 70,
                                                                height: 28,
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                mousedown: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onMouseDownOfSaveOrCancel()
                                                                },
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.handleManualCancel(
                                                                    "disableName"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v("Cancel")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "m-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "field col-7 p-0",
                                          attrs: { col: "7" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "m-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "col-10 p-0",
                                                  attrs: { col: "10" },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex",
                                                    },
                                                    [_vm._v("Description ")]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      readonly:
                                                        _vm.syncingStrategy,
                                                      disabled: _vm.disableDesc,
                                                    },
                                                    on: {
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.callSyncChanges()
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.handleBlurEvent()
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.description,
                                                      callback: function ($$v) {
                                                        _vm.description = $$v
                                                      },
                                                      expression: "description",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "col-2 p-0 mt-32",
                                                  attrs: { col: "2" },
                                                },
                                                [
                                                  _vm.disableDesc
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            bottom: "",
                                                            color:
                                                              "primarydark",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "font-22 icon-hover",
                                                                            attrs: {
                                                                              dark:
                                                                                "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.enableField(
                                                                                  "disableDesc"
                                                                                )
                                                                              },
                                                                            },
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-pencil-outline "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            3234774596
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Edit"),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.disableDesc
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-v-center m-h-input",
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-3",
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.description ||
                                                                  _vm.syncingStrategy,
                                                                color:
                                                                  "primary",
                                                                width: 70,
                                                                height: 28,
                                                              },
                                                              on: {
                                                                mousedown: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onMouseDownOfSaveOrCancel()
                                                                },
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.handleManualSave()
                                                                },
                                                              },
                                                            },
                                                            [_vm._v("Save")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.description ||
                                                                  _vm.syncingStrategy,
                                                                color:
                                                                  "primary",
                                                                width: 70,
                                                                height: 28,
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                mousedown: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onMouseDownOfSaveOrCancel()
                                                                },
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.handleManualCancel(
                                                                    "disableDesc"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v("Cancel")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-col", {
                                        staticClass: "col-5",
                                        attrs: { col: "5" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "m-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "field col-7 p-0",
                                          attrs: { col: "7" },
                                        },
                                        [
                                          _vm.strategyId &&
                                          _vm.targetAppsInDialog.length
                                            ? _c(
                                                "v-list",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c("label", [
                                                    _vm._v("Target Apps"),
                                                  ]),
                                                  _c(
                                                    "v-list-item-group",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.targetAppsInDialog,
                                                      function (item, i) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              disabled: true,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-avatar",
                                                              [
                                                                _c("v-img", {
                                                                  staticClass:
                                                                    "rounded-lg",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    "max-width":
                                                                      "30",
                                                                    "max-height":
                                                                      "30",
                                                                    "min-width":
                                                                      "20",
                                                                    "min-height":
                                                                      "20",
                                                                    src:
                                                                      item.appIcon,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    domProps: {
                                                                      textContent: _vm._s(
                                                                        item.appName
                                                                      ),
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "col-5 p-0",
                                          attrs: { col: "5" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column justify-end align-end",
                                            },
                                            [
                                              _vm.strategyId !== null &&
                                              !_vm.isDisabledViewAdsManagerButton &&
                                              _vm.targetEntityItems.length
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-2 d-flex align-self-end",
                                                    },
                                                    [_vm._v("Measurement")]
                                                  )
                                                : _vm._e(),
                                              _vm.strategyId !== null &&
                                              !_vm.isDisabledViewAdsManagerButton &&
                                              _vm.targetEntityItems.length
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        width: "166",
                                                        color: "primary",
                                                        depressed: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.redirectToAdsManager(
                                                            _vm.strategyId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View in Ads Manager "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.strategyId && _vm.targetEntityItems.length
                                    ? [
                                        _c("label", [
                                          _vm._v("Target Entities"),
                                        ]),
                                        _c("v-data-table", {
                                          attrs: {
                                            headers: _vm.targetEntityHeaders,
                                            items: _vm.targetEntityItems,
                                            "items-per-page": 5,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item.actions",
                                                fn: function (ref) {
                                                  var index = ref.index
                                                  return [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "no-m-no-p",
                                                            attrs: {
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  color:
                                                                    "primarydark",
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "font-22 icon-hover",
                                                                                  attrs: {
                                                                                    dark:
                                                                                      "",
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                                  on: {
                                                                                    click: function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.removeEntity(
                                                                                        index
                                                                                      )
                                                                                    },
                                                                                  },
                                                                                },
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-minus-circle-outline"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Remove entity"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            width: "127",
                                            color: "primary",
                                            depressed: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addTargetMsg()
                                            },
                                          },
                                        },
                                        [_vm._v("Add Target")]
                                      ),
                                      _vm.strategyId &&
                                      _vm.targetEntityItems.length
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                width: "127",
                                                color: "primary",
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewTrigger()
                                                },
                                              },
                                            },
                                            [_vm._v("Preview Trigger")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "field mt-2" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "black--text mb-1 d-flex",
                                        },
                                        [_vm._v("Target Goals")]
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          "item-text": "name",
                                          "item-value": "_id",
                                          items: _vm.getGoalsWithApp,
                                          placeholder: _vm.$t(
                                            "goals.selectGoal"
                                          ),
                                          outlined: "",
                                          dense: "",
                                          multiple: "",
                                          attach: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.callSyncChanges()
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedGoals,
                                          callback: function ($$v) {
                                            _vm.selectedGoals = $$v
                                          },
                                          expression: "selectedGoals",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "sub-heading-with-btn d-flex flex-wrap align-baseline mb-0",
                                    },
                                    [
                                      _c("h5", { staticClass: "black-text" }, [
                                        _vm._v("Time Line"),
                                      ]),
                                      _vm.syncingTimelines
                                        ? _c("v-progress-circular", {
                                            staticClass: "custom-loader",
                                            attrs: {
                                              color: "primary",
                                              indeterminate: "",
                                              size: "20",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: " " },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary ",
                                                depressed: "",
                                                disabled:
                                                  _vm.strategyId == null ||
                                                  _vm.rowHavingStrategyId(),
                                              },
                                              on: { click: _vm.addNewRow },
                                            },
                                            [_vm._v(" Add Entry ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  [
                                    _c("v-data-table", {
                                      attrs: {
                                        headers: _vm.timelineHeaders,
                                        items: _vm.timelineItems,
                                        "items-per-page": 5,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item.actions",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "field actions-wrap",
                                                  },
                                                  [
                                                    !_vm.editModes[index] &&
                                                    item.timelineId
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "font-22 icon-hover",
                                                                              attrs: {
                                                                                dark:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.enableEditMode(
                                                                                    index
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-pencil-outline "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Edit"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.editModes[index] ||
                                                    !item.description
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "font-22 icon-hover",
                                                                              attrs: {
                                                                                dark:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.cancelEditMode(
                                                                                    index
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-close "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Cancel"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "font-22 icon-hover",
                                                                          attrs: {
                                                                            dark:
                                                                              "",
                                                                            color:
                                                                              "primary",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.openTimelineDeleteDialog(
                                                                                index,
                                                                                item
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-delete-outline "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Delete"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.createdOn",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "field calendar-field",
                                                  },
                                                  [
                                                    _vm.isEditMode(index) ||
                                                    item.timelineId === null
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "calendar-menu pr-4 cursor-pointer",
                                                            attrs: {
                                                              depressed: "",
                                                              height: "42",
                                                              primary: "",
                                                              block: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.callTimelineChanges(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-datetime-picker",
                                                              {
                                                                attrs: {
                                                                  outlined: "",
                                                                  dense: "",
                                                                  datePickerProps:
                                                                    _vm.datePickerProps,
                                                                  disabled:
                                                                    _vm.syncingTimelinesApiCallInProgress,
                                                                },
                                                                on: {
                                                                  input: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.callTimelineChanges(
                                                                      item
                                                                    )
                                                                  },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.createdOn,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "createdOn",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.createdOn",
                                                                },
                                                              }
                                                            ),
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-calendar-clock"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.createdOn ||
                                                                ""
                                                            )
                                                          ),
                                                        ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.description",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "field" },
                                                  [
                                                    _vm.isEditMode(index) ||
                                                    item.timelineId === null
                                                      ? _c("v-text-field", {
                                                          attrs: {
                                                            rules:
                                                              _vm.descriptionRules,
                                                            maxlength: "250",
                                                            "error-messages": _vm.getErrorMessage(
                                                              item,
                                                              "description"
                                                            ),
                                                            disabled:
                                                              _vm.syncingTimelinesApiCallInProgress,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.callTimelineChanges(
                                                                item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              item.description,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "description",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.description",
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.description
                                                            )
                                                          ),
                                                        ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.category",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "field" },
                                                  [
                                                    _vm.isEditMode(index) ||
                                                    item.timelineId === null
                                                      ? _c("v-select", {
                                                          ref: "categorySelect",
                                                          attrs: {
                                                            items:
                                                              _vm.categoryOptions,
                                                            disabled:
                                                              _vm.syncingTimelinesApiCallInProgress,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.callTimelineChanges(
                                                                item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              item.category,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "category",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.category",
                                                          },
                                                        })
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.category
                                                            )
                                                          ),
                                                        ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.source",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "field text-wrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.source
                                                            ? item.source
                                                            : _vm.userName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                ],
                                2
                              ),
                            ],
                          ],
                          2
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-end mt-0 pa-5" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "127",
                                  color: "grey",
                                  depressed: "",
                                  disabled: _vm.saving,
                                  outlined: "",
                                },
                                on: { click: _vm.closeDialog },
                              },
                              [_vm._v(_vm._s(_vm.$t("goals.close")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("SQLPreview", { attrs: { preview: _vm.triggerPreview } }),
            _c("DecisionAlert", {
              attrs: {
                onlyMessage: true,
                DecisionAlert: _vm.decisionAlertFlag,
                DecisionAlertTitle: _vm.decisionAlertTitle,
                DecisionAlertIcon: 2,
                DecisionAlertMessage: _vm.decisionAlertMessage,
                DecisionAlertData: _vm.decisionAlertData,
              },
              on: {
                emitDecisionAlertCancelClicked: function ($event) {
                  return _vm.clearDecisionModel()
                },
                emitDecisionAlertOkClicked: function ($event) {
                  return _vm.clearDecisionModel()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("PermissionReadOnlyPopup", {
        attrs: { permissionMetadata: _vm.permissionReadOnlyMetadata },
      }),
      _c("DeleteStrategy", {
        attrs: { strategyDetailsForDeletion: _vm.strategyDeletionMetadata },
        on: {
          onDeleteStrategy: function ($event) {
            return _vm.onUpdate()
          },
        },
      }),
      _c("DeleteEntity", {
        attrs: { strategyEntityForDeletion: _vm.entityDeletionData },
        on: {
          rmEntity: function ($event) {
            return _vm.removeEntityExec($event)
          },
        },
      }),
      _c("DeleteTimeline", {
        attrs: { timelineDetailsForDeletion: _vm.timelineDeletionMetadata },
        on: {
          onDeleteTimeline: function ($event) {
            return _vm.onTimelineUpdate(_vm.strategyId)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }